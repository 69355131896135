import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Trianglify from 'trianglify';


const colors =  ["#fdae6b","#fd8d3c","#f16913","#d94801","#a63603","#7f2704"];

const colorFunc = function(x, y) {
    console.log(colors[Math.floor(Math.random() * colors.length)])
    return colors[Math.floor(Math.random() * colors.length)];
};



const backgroundPattern = Trianglify({
    width: window.innerWidth,
    height: window.innerHeight,
    color_function:colorFunc
})

const background = backgroundPattern.canvas();
background.id = 'background';
document.body.appendChild(background);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
